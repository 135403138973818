@import 'functions';
@import './NLRTM-font-family.scss';
@import './strip-unit.scss';
@import './spacing.scss';

//==================
// Colors
//==================

// Blues
$darkblue: #001f4b;
$mediumblue: #0087c6;
$lightblue: #429ad1;
$lightblueAlternative: #009cd6; // This one is slightly lighter than $lightblue, but comes out better in the operational dashboard.
$veryLightBlue: #f0f7fa;
$selectedLightBlue: #e0eef4;
$cerulean: #0693ca;

// White and greys
$white: white;
$broken-white: #f8f8f8;
$verylightgrey: #f2f2f1;
$lightgrey: #ddd;
$mediumgrey: #cccccc;
$darkgrey: #9b9b9b;
$transparent-white-20: rgba(255, 255, 255, 0.2);
$ebb: #e6e4e2;
$cloudy: #ccc8c5;
$very-light-grey: #efefef;

$red: #d0021b;
$darkred: #db392a;
$green: #46be41;
$black: black;
$text-color-alt: $white;

// ==================
// Breakpoints
//
// These breakpoints are not completely arbitrary
// They are based on the most common screen resolutions.
// They are a sort of standard in UI libraries, like bootstrap: https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
// - 768px is the width of an iPad in portrail mode,
// - 993px was the available space in old 1024px resolution monitors after the scrollbar. It's also for iPad in landscape.
// ==================
$breakpoint-extra-small: em(576px) !default;
$breakpoint-small: em(768px) !default;
$breakpoint-medium: em(993px) !default;
$breakpoint-large: em(1200px) !default;
$breakpoint-extra-large: em(1920px) !default;

//==================
// Environment
// - these should be coming from webpack/sass-loader
// - good practice to set defaults though
//==================
$PUBLIC_URL: '' !default;

//==================
// Theme Colors
//==================
$theme-color-actual: #5d9e16;
$theme-color-main: #001f4b;
$theme-color-alt: #009cd6;
$theme-color-active: #ffd800;
$theme-color-global: #0b0b0b;
$theme-color-text: #333333;
$theme-color-warning: $darkgrey;
$theme-background-main: $white;
$theme-background-alt: #f3f2f1;
$theme-color-services: linear-gradient(-90deg, #1eaddd, #264c85);
$theme-color-services-alt: linear-gradient(-90deg, #1eaddd, #227bb0);
$theme-errors-main: $red;
$theme-success-main: #5d9e16;
$theme-color-yellow: #f9d949;
$theme-color-light-blue: rgb(119, 192, 221);
$theme-background-color: #ececec;
$theme-border-color: #eaeaea;
$theme-head-header-background: $white;
$theme-header-color: $darkblue;
$theme-current-time-color: $red;
$terminal-border-color: #4a4a4a;
$ship-color: #001f4b;
$ship-text-color: $white;
$shadow-color: rgba(0, 0, 0, 0.5);
$ui-panel-shadow: 0 2px 4px 0 $shadow-color;
$logo-color: #002e5c;

//==================
// Typography
//==================
$title-font-size: 36px;
$large-font-size: 20px;
$default-font-size: 16px;
$small-font-size: 14px;
$smaller-font-size: 12px;
$smallest-font-size: 10px;
$font-family-strong: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
$font-family-medium: 'NLRTM-Medium', 'Trebuchet MS', Arial, sans-serif;
$font-family-regular: 'NLRTM-Regular', 'Trebuchet MS', Arial, sans-serif;
$theme-font-family: $font-family-regular;
$font-family-headings: $font-family-strong;
$font-family-sub-headings: $font-family-strong;

//==================
// Filter Styles
//==================
$filter-background-color: $lightblue;
$filter-option-selected-color: #3f51b5;
$filter-option-hover-background-color: #eeeeee;
$filter-option-title-fontsize: 15px;
$filter-option-fontsize: $small-font-size;
$filter-option-lineheight: 40px;
$filter-option-padding: 0 spacing(2);
$filter-list-vertical-margin: 12px;
$filters-selected-option-list-border: 1px solid $lightgrey;

//==================
// Ship Colors
//==================
$color-ship-subject: $theme-color-main;
$color-ship-contextship: $theme-color-main;
$color-ship-custom: #ff3823;
$color-secondary-ship: darken($red, 20%);
$color-ship-deepdraught: #630100;
$color-ship-seavessel: #9e9e9e;
$color-ship-other-portcall: #a9a9a9;
$color-ship-neighbour: #a9a9a9;
$color-ship-other: #dddddd;
$color-ship-other-services: #e4a1e1;
$color-alongside-services: #ce72c6;
$color-ship-bunker: $color-alongside-services;
$color-ship-crane: $color-alongside-services;
$color-ship-garbage: $color-alongside-services;
$color-ship-mooring: #5d9e16;
$color-ship-pilot: #3b8dd2;
$color-ship-tug: #3e6892;
$color-ship-waste: $color-alongside-services;
$color-ship-surveyor: #b2791b;
$color-ship-cargo: #ffbe55;
$color-port-berth: $theme-color-main;
$color-exchange-vessel: $darkgrey;

//==================
// Status colors
//==================
$status-color-expected: #a6a6a6;
$status-color-inbound: #a6a6a6;
$status-color-anchored: #a6a6a6;
$status-color-alongside: $lightblueAlternative;
$status-color-shifting: #a6a6a6;
$status-color-outbound: #a6a6a6;
$status-color-left-port: $color-alongside-services;
$status-color-not-available: #a6a6a6;

//==================
// Portcall statusses
//==================
$color-status-inbound: #c2e3fb;
$color-status-in-port: #429ad1;
$color-status-alongside: #ffd899;
$color-status-default: #eee;
$portcall-status-font-size: 12px;

//==================
// Pending ships
//==================
$pendingShipsFontSize: em(12px);

//==================
// Details layer
//==================
$details-color: #002c5f;
$details-font-size: $small-font-size;
$details-property-font: $font-family-regular;
$details-value-font: $font-family-strong;
$details-heading-font-size: 16px;
$details-heading-color: #002c5f;

//==================
// Portbase input
//==================
$portbaseInputFormFont: $font-family-regular;
$portbaseInputFormFontBold: $font-family-headings;
$inputBorderColor: #e2dfdc;
$inputErrorBorderColor: $red;
$disabledTextColor: #9b9b9b;
$inputLabelBackground: #e3e3e3;
$inputDisabledBackgroundColor: #f1f1f1;
$inputHeight: 40px;
$iconFontSize: 16px;
$inputFontSize: $small-font-size;
$validationsFontSize: 13px;
$validationsTitleFontSize: $small-font-size;

//==================
// Feedback screen
//==================
$feedbackScreenTextColor: #a6a6a6;
$feedbackScreenFontSize: 18px;
$feedbackScreenFontSizeSmall: $small-font-size;

//==================
// Terminal Viewer
//==================
$ship-planning-border-color: $darkgrey;
$ship-planning-hover-shadow: 0px 0px 10px 3px rgba($black, 0.3);

// Login
//==================
$loginSideBarFormWidth: 488px;

// AgentView
//==================
$topButtonsPosition: 20px;
$mapToggleButtonPositionRight: 20px;
$mapToggleButtonPositionLeft: 20px;
$mapToggleButtonWidth: 30px;
$mapToggleButtonHeight: 30px;
$mapToggleButtonBoxShadowWidth: 2px;
$mapToggleButtonActiveBackgroundColor: #001f4b;
$mapToggleButtonBorderRadius: 4px;

// spacings
$addVesselToMapTopSpacing: $mapToggleButtonHeight + $topButtonsPosition + 10px;
$mapToggleButtonsTopSpacing: $mapToggleButtonHeight + 10px;
$mapSpeedLegendTopSpacing: $mapToggleButtonHeight + 10px;

// tops
$mapFirstToggleButtonsTop: 90px;
$addVesselToMapTop: $mapFirstToggleButtonsTop + 10px;
$mapRadiusToggleButtonTop: $mapFirstToggleButtonsTop + $addVesselToMapTopSpacing;

// Radius toggle will be hidden until radius issues are fixed
$mapSpeedTraceToggleButtonTop: $addVesselToMapTop + $addVesselToMapTopSpacing + $mapToggleButtonsTopSpacing;

// $mapRadiusToggleButtonTop + $mapToggleButtonsTopSpacing;
$mapSpeedLegendTop: $topButtonsPosition;

//==================
// Legal terms
//==================
$downloadLinksBackgroundColor: #f3f3f3;

//==================
// Modals
//==================
$modal-backdrop-background: rgba($black, 0.4);

//==================
// Drawer
//==================
$drawerHeaderHeight: 50px;
